import React, { useState, useEffect } from "react"
import "./index.css"

import CardsWrap from "../../utils/CardsWrap.jsx"

import { getFirestore, collection, query, where, getDoc, getDocs, doc, deleteDoc, updateDoc, setDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';

import { FaTrashAlt } from 'react-icons/fa';


//firebase consts
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
    authDomain: "sitocoro-e5767.firebaseapp.com",
    projectId: "sitocoro-e5767",
    storageBucket: "sitocoro-e5767.appspot.com",
    messagingSenderId: "516021736965",
    appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
    measurementId: "G-02F3QZXJFH"
  })
}
const db = getFirestore(firebase.app());
const ch = query(collection(db, "chants"));
const da = query(collection(db, "days"));

function DragDropLayout(props) {
  const [chantsItems, setChantsItems] = React.useState([])
  const [chantsTypes, setChantsTypes] = React.useState([])
  const [newChantsItem, setNewChantsItem] = React.useState("")

  useEffect(function appRunTimer() {
    retriveData();
  }, [props.docId])

  useEffect(() => {
    console.log(chantsTypes)

    let i;
    for (i = 0; i < chantsTypes.length; i++) {
      if (document.getElementsByClassName("select" + i)[0] == undefined) { return }

      document.getElementsByClassName("select" + i)[0].value = chantsTypes[i]
    }
  }, [chantsTypes]);

  //retrive data
  async function retriveData() {
    let thisDay = props.dayDoc;
    let chantsArray = thisDay.data().chants;
    setChantsItems([]);
    setChantsTypes([]);
    chantsArray.forEach((map, i) => {
      Object.entries(map).forEach(([id, type]) => {
        addChantTitleToArray(id);
        setChantsTypes(oldArray => [...oldArray, type]);
      });
    });
  }

  async function addChantTitleToArray(id) {
    let chant;
    const chantsRef = await getDocs(query(collection(db, "chants"), where(firebase.firestore.FieldPath.documentId(), "==", id)));
    chantsRef.forEach((doc) => {
      if (doc.id == id) {
        chant = doc;
      }
    });
    if (chant == null) {
      const thisDayRef = await getDocs(da);
      thisDayRef.forEach((docc) => {
        if (docc.id == props.docId) {
          let chants = docc.data().chants;
          let i = 0;
          chants.forEach(element => {
            if(Object.keys[element][0] == chant.id){
              chants.splice(i, 1)
            }
            i++;
          });
          setDoc(doc(db, "days", props.docId), {
            [`chants`]: chants
          });
        }
      });
    }
    setChantsItems(oldArray => [...oldArray, chant.data().title]);
  }

  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef(null)
  const dragOverItem = React.useRef(null)

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let _chantsItems = [...chantsItems]
    let _chantsTypes = [...chantsTypes]

    //remove and save the dragged item content
    const draggedItemContent = _chantsItems.splice(dragItem.current, 1)[0]
    const draggedTypeContent = _chantsTypes.splice(dragItem.current, 1)[0]

    //switch the position
    _chantsItems.splice(dragOverItem.current, 0, draggedItemContent)
    _chantsTypes.splice(dragOverItem.current, 0, draggedTypeContent)

    //reset the position ref
    dragItem.current = null
    dragOverItem.current = null

    //update the actual array
    setChantsItems(_chantsItems)
    setChantsTypes(_chantsTypes)

    var i;
    for (i = 0; i < chantsTypes.length; i++) {
      document.getElementsByClassName("select" + i)[0].value = chantsTypes[i]
    }
  }

  //handle name change
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewChantsItem(e.target.value)
  }

  //handle new item addition
  const handleAddItem = (title, id) => {
    const _chantsItems = [...chantsItems]
    _chantsItems.push(title)
    setChantsItems(_chantsItems)
    setChantsTypes(oldArray => [...oldArray, "INGRESSO"])
  }

  //save
  async function pushToDatabase() {
    let chantsIds = [];

    await Promise.all(chantsItems.map(async (item) => {
      const chantsRef = await getDocs(query(collection(db, "chants"), where("title", "==", item)));
      let chant;
      chantsRef.forEach((doc) => {
        chantsIds.push(doc.id)
      });
    }));

    let thisDay = props.dayDoc;

    let chantMapsArray = [];
    chantsIds.forEach((docId, i) => {
      let obj = {};
      obj[docId] = chantsTypes[i];
      chantMapsArray.push(obj)
    });


    await updateDoc(thisDay.ref, {
      "chants": chantMapsArray,
      "divisa": props.divisa,
      "prove": props.prove,
      "salmo": props.salmo == null ? "--" : props.salmo,
      "preghiera": props.preghiera == null ? "--" : props.preghiera
    }).then(() => {
      props.closeAction();
    });
  }

  //delete
  const deleteCard = (index) => {
    let array = [...chantsItems];
    array.splice(index, 1);
    setChantsItems(array)
    let array2 = [...chantsTypes];
    array2.splice(index, 1);
    setChantsTypes(array2)
    //array.forEach((element, index) => {
    //  setChantType(index, array2[index]);
    //});
  }

  //set chant type
  const setChantType = (index, value) => {
    let array = [...chantsTypes];
    array[index] = value;
    setChantsTypes(array)
  }

  return (
    <div className="layout" id={props.docId}>
      {/**<h2>Chants List</h2>
			<div className="input-group">
				<input
					type="text"
					name="chantsName"
					placeholder="e.g Banana"
					onChange={handleNameChange}
				/>
				<button className="btn" onClick={handleAddItem}>
					Add item
				</button>
			</div>**/}

      <div className="list-container">
        <div className="max-w-0 m-4 mt-12">
          <button className="button-5" onClick={pushToDatabase}>Salva</button>
        </div>
        {chantsItems.map((item, index) => (
          <div
            key={index}
            className="card list-item"
            draggable
            onDragStart={(e) => { dragItem.current = index }}
            onDragEnter={(e) => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}>
            <a href="#" className="mx-2 my-3" onClick={() => deleteCard(index)}><FaTrashAlt className="h-6" /></a>
            <i className="fa-solid fa-bars"></i>
            <h5 className="py-5"><b>{item}</b></h5>

            <select className={`h-8 my-4 ml-auto mr-6 ${"select" + index}`} defaultValue={chantsTypes[index]} onChange={(e) => setChantType(index, e.target.value)}>
              <option value="╔════| LIBRETTO |════╗">╔════| LIBRETTO |════╗</option>
              <option value="INGRESSO">INGRESSO</option>
              <option value="ATTO PENITENZIALE">ATTO PENITENZIALE</option>
              <option value="INNO DI LODE">INNO DI LODE</option>
              <option value="SALMO RESPONSORIALE">SALMO RESPONSORIALE</option>
              <option value="AL VANGELO">AL VANGELO</option>
              <option value="DOPO IL VANGELO">DOPO IL VANGELO</option>
              <option value="OFFERTORIO">OFFERTORIO</option>
              <option value="ACCLAMAZIONE">ACCLAMAZIONE</option>
              <option value="ANAMNESI">ANAMNESI</option>
              <option value="SPEZZARE DEL PANE">SPEZZARE DEL PANE</option>
              <option value="COMUNIONE">COMUNIONE</option>
              <option value="COMUNIONE (1° Canto)">COMUNIONE (1° Canto)</option>
              <option value="COMUNIONE (2° Canto)">COMUNIONE (2° Canto)</option>
              <option value="COMUNIONE (3° Canto)">COMUNIONE (3° Canto)</option>
              <option value="FINALE">FINALE</option>
              <option value="FINALE (1° Canto)">FINALE (1° Canto)</option>
              <option value="FINALE (2° Canto)">FINALE (2° Canto)</option>
              <option value="FINALE (3° Canto)">FINALE (3° Canto)</option>
              <option value="INIZIO">INIZIO</option>
              <option value="ROSARIO">ROSARIO</option>
              <option value="ERNARIO">ERNARIO</option>
              <option value="BENEDIZIONE">BENEDIZIONE</option>
              <option value="PRECONIO">PRECONIO</option>
              <option value="CANTICO">CANTICO</option>
              <option value="CANTICO DI MOSE'">CANTICO DI MOSE'</option>
              <option value="ANNUNCIO RESURREZIONE">ANNUNCIO RESURREZIONE</option>
              <option value="PROCESSIONE">PROCESSIONE</option>
              <option value="SALLENDA">SALLENDA</option>
              <option value="LUCERNARIO">LUCERNARIO</option>
              <option value="RITO DELLA LUCE">RITO DELLA LUCE</option>
              <option value="INNO">INNO</option>
              <option value="KYRIE ELEISON">KYRIE ELEISON</option>
              <option value="12 KYRIE">12 KYRIE</option>
              <option value="RESPONSORIO">RESPONSORIO</option>
              <option value="ADORAZIONE DELLA CROCE">ADORAZIONE DELLA CROCE</option>
              <option value="AGNELLO DI DIO">AGNELLO DI DIO</option>
              <option value="SALMO 21">SALMO 21</option>
              <option value="SALMO">SALMO</option>
              <option value="SALMELLO">SALMELLO</option>
              <option value="AL VANGELO">AL VANGELO</option>
              <option value="DEPOS. GESU' BAMBINO">DEPOS. GESU' BAMBINO</option>
              <option value="CRISMAZIONE">CRISMAZIONE</option>
              <option value="DOPO L'OMELIA">DOPO L'OMELIA</option>
              <option value="PREGHIERA DEI FEDELI">PREGHIERA DEI FEDELI</option>
              <option value="PROFESSIONE DI FEDE">PROFESSIONE DI FEDE</option>
              <option value="PREFAZIO">PREFAZIO</option>
              <option value="DOSSOLOGIA">DOSSOLOGIA</option>
              <option value="PADRE NOSTRO">PADRE NOSTRO</option>
              <option value="INVOCAZIONE ALLO SPIRITO SANTO">INVOCAZIONE ALLO SPIRITO SANTO</option>
              <option value="EMBOLISMO">EMBOLISMO</option>
              <option value="CANTICO DELLA B.V.M.">CANTICO DELLA B.V.M.</option>
              <option value="ESPOSIZIONE">ESPOSIZIONE</option>
              <option value="IMPOSIZIONE CENERI">IMPOSIZIONE CENERI</option>
              <option value="PROCESSIONE">PROCESSIONE</option>
              <option value="REGINA CAELI">REGINA CAELI</option>
              <option value="SEQUENZA">SEQUENZA</option>
              <option value="O DIO VIENI A SALVARMI">O DIO VIENI A SALVARMI</option>
              <option value="SALMODIA">SALMODIA</option>
              <option value="CANTICO DI SIMEONE">CANTICO DI SIMEONE</option>
              <option value="ANTIFONA ALLA B.V.M.">ANTIFONA ALLA B.V.M.</option>
              <option value="CANTICO DI ZACCARIA">CANTICO DI ZACCARIA</option>
              <option value="SALMI LAUDATIVI">SALMI LAUDATIVI</option>
              <option value="SALMO DIRETTO">SALMO DIRETTO</option>
            </select>
          </div>
        ))}
      </div>

      <CardsWrap classes="wrapDay" addButton={true} onClick={handleAddItem}/>
    </div>
  )
}

export default DragDropLayout
